<template>
  <product title="Amazfit PowerBuds"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="699"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://gxb.mmstat.com/gxb.gif?t=https%3A%2F%2Fequity.tmall.com%2Ftm%3FagentId%3D557501%26_bind%3Dtrue%26bc_fl_src%3Dtmall_market_llb_1_722125%26llbPlatform%3D_pube%26llbOsd%3D1%26mm_unid%3D1_4453579_560501015e6d5455040009556d02545e6f0d51050a0c&v=e7c5e30f7598&di=__IDFA__&dim=__IMEI__&bc_fl_src=tmall_market_llb_1_722125&llbPlatform=_pubu&llbOsd=1&agentId=557501"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'powerbuds',
  components: {
    Product
  },
  data () {
    return {
      slogan: '运动心率监测 | 磁吸运动耳挂 | 澎湃震撼音效',
      colors: [
        '暗影黑',
        '清爽白',
        '竞速黄'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/powerbuds.mp4',
        poster: CDN_URL + '/images/poster/powerbuds.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/powerbuds/swiper/1.png',
          CDN_URL + '/images/product/powerbuds/swiper/2.png',
          CDN_URL + '/images/product/powerbuds/swiper/3.png',
          CDN_URL + '/images/product/powerbuds/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/powerbuds/swiper/5.png',
          CDN_URL + '/images/product/powerbuds/swiper/6.png',
          CDN_URL + '/images/product/powerbuds/swiper/7.png',
          CDN_URL + '/images/product/powerbuds/swiper/8.png'
        ],
        [
          CDN_URL + '/images/product/powerbuds/swiper/9.png',
          CDN_URL + '/images/product/powerbuds/swiper/10.png',
          CDN_URL + '/images/product/powerbuds/swiper/11.png',
          CDN_URL + '/images/product/powerbuds/swiper/12.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        CDN_URL + '/images/product/powerbuds/detail/a01.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a02.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a03.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a04.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a05.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a06.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a07.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a08.jpg',
        CDN_URL + '/images/common/zepp-powerbuds.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a09.jpg',
        CDN_URL + '/images/product/powerbuds/detail/a10.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/powerbuds/detail/a11.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
